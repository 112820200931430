/* istanbul ignore file */

import { Text } from '@react-pdf/renderer';
import Section from '@rsa-digital/evo-shared-components/components/pdf/components/Section';
import { EmphasisParagraphText } from '@rsa-digital/evo-shared-components/components/pdf/components/typography';
import React, { Fragment } from 'react';
import { DefaultTheme } from 'styled-components';
import { Detail } from 'components/CheckYourDetails/DetailsSection/DetailGroupSection';
import DetailGroupSection, { DetailGroupSectionProps } from './DetailGroupSection';

export type DetailsSectionProps = {
  quoteReference: Detail;
  yourCoverDatesSectionProps: DetailGroupSectionProps;
  yourCoverLevelSectionProps: DetailGroupSectionProps;
  aboutYouSectionProps: DetailGroupSectionProps;
  additionalInformationSectionProps: DetailGroupSectionProps;
  jointPolicyholderSectionProps?: DetailGroupSectionProps;
  yourPetsSectionProps: DetailGroupSectionProps[];
  excessSectionProps: DetailGroupSectionProps[];
  theme: DefaultTheme;
};

const DetailsSection: React.FC<DetailsSectionProps> = ({
  quoteReference,
  yourCoverDatesSectionProps,
  yourCoverLevelSectionProps,
  aboutYouSectionProps,
  jointPolicyholderSectionProps,
  additionalInformationSectionProps,
  yourPetsSectionProps,
  excessSectionProps,
  theme,
}) => (
  <>
    <Section>
      <Text>
        {quoteReference.label}
        <EmphasisParagraphText> {quoteReference.value}</EmphasisParagraphText>
      </Text>
      <DetailGroupSection theme={theme} {...yourCoverDatesSectionProps} />
      <DetailGroupSection theme={theme} {...yourCoverLevelSectionProps} />
      <DetailGroupSection theme={theme} {...additionalInformationSectionProps} />
      {yourPetsSectionProps.map((pet, index) => (
        <Fragment key={pet.sectionHeading}>
          <DetailGroupSection
            sectionHeading={excessSectionProps[index].sectionHeading}
            details={excessSectionProps[index].details}
            theme={theme}
          />
          <DetailGroupSection theme={theme} {...pet} />
        </Fragment>
      ))}
      <DetailGroupSection theme={theme} {...aboutYouSectionProps} />
      {jointPolicyholderSectionProps && (
        <DetailGroupSection theme={theme} {...jointPolicyholderSectionProps} />
      )}
    </Section>
  </>
);

export default DetailsSection;

/* istanbul ignore file */

import { Text, View } from '@react-pdf/renderer';
import BulletItem from '@rsa-digital/evo-shared-components/components/pdf/components/BulletItem';
import Question from '@rsa-digital/evo-shared-components/components/pdf/components/Question';
import RichText from '@rsa-digital/evo-shared-components/components/pdf/components/RichText';
import Section from '@rsa-digital/evo-shared-components/components/pdf/components/Section';
import SimpleDivider from '@rsa-digital/evo-shared-components/components/pdf/components/SimpleDivider';
import { Assumptions } from 'businessLogic/aggregatorAssumptions';
import React, { Fragment } from 'react';
import { DefaultTheme } from 'styled-components';
import { Heading2 } from '../typography';

export type AggregatorSectionProps = {
  theme: DefaultTheme;
  sectionHeading: string;
  subheading: string;
  confirmationText: string;
  assumptions: Assumptions | undefined;
  confirmationAnswer: string;
  eligibilityInfoText: string;
};

const AggregatorSection: React.FC<AggregatorSectionProps> = ({
  sectionHeading,
  subheading,
  confirmationText,
  assumptions,
  confirmationAnswer,
  theme,
  eligibilityInfoText,
}) => (
  <>
    <Section>
      <Heading2>{sectionHeading}</Heading2>
      <SimpleDivider theme={theme} />
      <View style={{ marginTop: 24 }} />
      <Text>{subheading}</Text>
      <View style={{ marginBottom: 16 }} />
      {assumptions?.assumptions.map((assumption) => (
        <Fragment key={assumption.displayText}>
          <BulletItem theme={theme}>{assumption.displayText}</BulletItem>
        </Fragment>
      ))}
      <RichText html={eligibilityInfoText} theme={theme} />
      <Question question={confirmationText} answer={confirmationAnswer} />
      <View style={{ marginBottom: 16 }} />
    </Section>
  </>
);

export default AggregatorSection;

/* istanbul ignore file */

import { View } from '@react-pdf/renderer';
import Link from '@rsa-digital/evo-shared-components/components/pdf/components/Link';
import Section from '@rsa-digital/evo-shared-components/components/pdf/components/Section';
import SimpleDivider from '@rsa-digital/evo-shared-components/components/pdf/components/SimpleDivider';
import React from 'react';
import { DefaultTheme } from 'styled-components';
import { Heading2 } from '../typography';

export type PdfLink = {
  url: string;
  text: string;
};

export type DocumentsSectionProps = {
  theme: DefaultTheme;
  heading: string;
  productInformationDocument: PdfLink;
  essentialInformation: PdfLink;
  policyBooklet: PdfLink;
};

const DocumentsSection: React.FC<DocumentsSectionProps> = ({
  theme,
  heading,
  productInformationDocument,
  essentialInformation,
  policyBooklet,
}) => (
  <>
    <Section>
      <Heading2>{heading}</Heading2>
      <SimpleDivider theme={theme} />
      <View style={{ marginTop: 24 }} />
      <Link href={productInformationDocument.url} theme={theme}>
        {productInformationDocument.text}
      </Link>
      <View style={{ marginTop: 8 }} />
      <Link href={essentialInformation.url} theme={theme}>
        {essentialInformation.text}
      </Link>
      <View style={{ marginTop: 8 }} />
      <Link href={policyBooklet.url} theme={theme}>
        {policyBooklet.text}
      </Link>
    </Section>
    <View style={{ marginBottom: 24 }} />
  </>
);

export default DocumentsSection;

/* istanbul ignore file */

import { View } from '@react-pdf/renderer';
import RichText from '@rsa-digital/evo-shared-components/components/pdf/components/RichText';
import Section from '@rsa-digital/evo-shared-components/components/pdf/components/Section';
import SimpleDivider from '@rsa-digital/evo-shared-components/components/pdf/components/SimpleDivider';
import React, { Fragment } from 'react';
import { DefaultTheme } from 'styled-components';
import { Heading2 } from '../typography';

export type SummaryOfNeedsSectionProps = {
  theme: DefaultTheme;
  textBeforeStatement: string | undefined;
  textAfterStatement: string | undefined;
  perPetStatements:
    | {
        excessStatement: string;
        coPaymentStatement: string;
      }[]
    | undefined;
};

const SummaryOfNeedsSection: React.FC<SummaryOfNeedsSectionProps> = ({
  perPetStatements,
  textBeforeStatement,
  textAfterStatement,
  theme,
}) => (
  <>
    <Section>
      <Heading2>Summary of your needs</Heading2>
      <SimpleDivider theme={theme} />
      <View style={{ marginTop: 24 }} />
      {textBeforeStatement && <RichText theme={theme} html={textBeforeStatement} />}
      {perPetStatements &&
        perPetStatements.map((statements, index) => (
          // This is for pdf generation, no concerns about rerendering once generated
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={index}>
            <RichText theme={theme} html={statements.excessStatement} />
            <RichText theme={theme} html={statements.coPaymentStatement} />
          </Fragment>
        ))}
      {textAfterStatement && <RichText theme={theme} html={textAfterStatement} />}
    </Section>
  </>
);

export default SummaryOfNeedsSection;
